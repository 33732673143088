export const productSizes = [
    { type: "Menswear", category: "Tops", size: "XXS" },
    { type: "Menswear", category: "Tops", size: "XS" },
    { type: "Menswear", category: "Tops", size: "S" },
    { type: "Menswear", category: "Tops", size: "M" },
    { type: "Menswear", category: "Tops", size: "L" },
    { type: "Menswear", category: "Tops", size: "XL" },
    { type: "Menswear", category: "Tops", size: "XXL" },
    { type: "Menswear", category: "Tops", size: "XXXL" },

    { type: "Menswear", category: "Bottoms", size: "XXS" },
    { type: "Menswear", category: "Bottoms", size: "XS" },
    { type: "Menswear", category: "Bottoms", size: "S" },
    { type: "Menswear", category: "Bottoms", size: "M" },
    { type: "Menswear", category: "Bottoms", size: "L" },
    { type: "Menswear", category: "Bottoms", size: "XL" },
    { type: "Menswear", category: "Bottoms", size: "XXL" },
    { type: "Menswear", category: "Bottoms", size: "XXXL" },
    { type: "Menswear", category: "Bottoms", size: '24"' },
    { type: "Menswear", category: "Bottoms", size: '25"' },
    { type: "Menswear", category: "Bottoms", size: '26"' },
    { type: "Menswear", category: "Bottoms", size: '27"' },
    { type: "Menswear", category: "Bottoms", size: '28"' },
    { type: "Menswear", category: "Bottoms", size: '29"' },
    { type: "Menswear", category: "Bottoms", size: '30"' },
    { type: "Menswear", category: "Bottoms", size: '31"' },
    { type: "Menswear", category: "Bottoms", size: '32"' },
    { type: "Menswear", category: "Bottoms", size: '33"' },
    { type: "Menswear", category: "Bottoms", size: '34"' },
    { type: "Menswear", category: "Bottoms", size: '35"' },
    { type: "Menswear", category: "Bottoms", size: '36"' },
    { type: "Menswear", category: "Bottoms", size: '37"' },
    { type: "Menswear", category: "Bottoms", size: '38"' },
    { type: "Menswear", category: "Bottoms", size: '39"' },
    { type: "Menswear", category: "Bottoms", size: '40"' },

    { type: "Menswear", category: "Underwear", size: "XXS" },
    { type: "Menswear", category: "Underwear", size: "XS" },
    { type: "Menswear", category: "Underwear", size: "S" },
    { type: "Menswear", category: "Underwear", size: "M" },
    { type: "Menswear", category: "Underwear", size: "L" },
    { type: "Menswear", category: "Underwear", size: "XL" },
    { type: "Menswear", category: "Underwear", size: "XXL" },
    { type: "Menswear", category: "Underwear", size: "XXXL" },

    { type: "Menswear", category: "Outwear", size: "XXS" },
    { type: "Menswear", category: "Outwear", size: "XS" },
    { type: "Menswear", category: "Outwear", size: "S" },
    { type: "Menswear", category: "Outwear", size: "M" },
    { type: "Menswear", category: "Outwear", size: "L" },
    { type: "Menswear", category: "Outwear", size: "XL" },
    { type: "Menswear", category: "Outwear", size: "XXL" },
    { type: "Menswear", category: "Outwear", size: "XXXL" },

    { type: "Menswear", category: "Footwear", size: "Breifs" },
    { type: "Menswear", category: "Footwear", size: "UK 3" },
    { type: "Menswear", category: "Footwear", size: "UK 3 1/2" },
    { type: "Menswear", category: "Footwear", size: "UK 4" },
    { type: "Menswear", category: "Footwear", size: "UK 4 1/2" },
    { type: "Menswear", category: "Footwear", size: "UK 5" },
    { type: "Menswear", category: "Footwear", size: "UK 5 1/2" },
    { type: "Menswear", category: "Footwear", size: "UK 6" },
    { type: "Menswear", category: "Footwear", size: "UK 6 1/2" },
    { type: "Menswear", category: "Footwear", size: "UK 7" },
    { type: "Menswear", category: "Footwear", size: "UK 7 1/2" },
    { type: "Menswear", category: "Footwear", size: "UK 8" },
    { type: "Menswear", category: "Footwear", size: "UK 8 1/2" },
    { type: "Menswear", category: "Footwear", size: "UK 9" },
    { type: "Menswear", category: "Footwear", size: "UK 9 1/2" },
    { type: "Menswear", category: "Footwear", size: "UK 10" },
    { type: "Menswear", category: "Footwear", size: "UK 10 1/2" },
    { type: "Menswear", category: "Footwear", size: "UK 11" },
    { type: "Menswear", category: "Footwear", size: "UK 11 1/2" },
    { type: "Menswear", category: "Footwear", size: "UK 12" },

    { type: "Menswear", category: "Swimwear", size: "XXS" },
    { type: "Menswear", category: "Swimwear", size: "XS" },
    { type: "Menswear", category: "Swimwear", size: "S" },
    { type: "Menswear", category: "Swimwear", size: "M" },
    { type: "Menswear", category: "Swimwear", size: "L" },
    { type: "Menswear", category: "Swimwear", size: "XL" },
    { type: "Menswear", category: "Swimwear", size: "XXL" },
    { type: "Menswear", category: "Swimwear", size: "XXXL" },

    { type: "Womenswear", category: "Tops", size: "4" },
    { type: "Womenswear", category: "Tops", size: "6" },
    { type: "Womenswear", category: "Tops", size: "8" },
    { type: "Womenswear", category: "Tops", size: "10" },
    { type: "Womenswear", category: "Tops", size: "12" },
    { type: "Womenswear", category: "Tops", size: "14" },
    { type: "Womenswear", category: "Tops", size: "16" },
    { type: "Womenswear", category: "Tops", size: "18" },
    { type: "Womenswear", category: "Tops", size: "20" },
    { type: "Womenswear", category: "Tops", size: "22" },
    { type: "Womenswear", category: "Tops", size: "24" },
    { type: "Womenswear", category: "Tops", size: "XS" },
    { type: "Womenswear", category: "Tops", size: "S" },
    { type: "Womenswear", category: "Tops", size: "M" },
    { type: "Womenswear", category: "Tops", size: "L" },
    { type: "Womenswear", category: "Tops", size: "XL" },
    { type: "Womenswear", category: "Tops", size: "XXL" },

    { type: "Womenswear", category: "Bottoms", size: "4" },
    { type: "Womenswear", category: "Bottoms", size: "6" },
    { type: "Womenswear", category: "Bottoms", size: "8" },
    { type: "Womenswear", category: "Bottoms", size: "10" },
    { type: "Womenswear", category: "Bottoms", size: "12" },
    { type: "Womenswear", category: "Bottoms", size: "14" },
    { type: "Womenswear", category: "Bottoms", size: "16" },
    { type: "Womenswear", category: "Bottoms", size: "18" },
    { type: "Womenswear", category: "Bottoms", size: "20" },
    { type: "Womenswear", category: "Bottoms", size: "22" },
    { type: "Womenswear", category: "Bottoms", size: "24" },
    { type: "Womenswear", category: "Bottoms", size: "XS" },
    { type: "Womenswear", category: "Bottoms", size: "S" },
    { type: "Womenswear", category: "Bottoms", size: "M" },
    { type: "Womenswear", category: "Bottoms", size: "L" },
    { type: "Womenswear", category: "Bottoms", size: "XL" },
    { type: "Womenswear", category: "Bottoms", size: "XXL" },
    { type: "Womenswear", category: "Bottoms", size: '24"' },
    { type: "Womenswear", category: "Bottoms", size: '25"' },
    { type: "Womenswear", category: "Bottoms", size: '26"' },
    { type: "Womenswear", category: "Bottoms", size: '27"' },
    { type: "Womenswear", category: "Bottoms", size: '28"' },
    { type: "Womenswear", category: "Bottoms", size: '29"' },
    { type: "Womenswear", category: "Bottoms", size: '30"' },
    { type: "Womenswear", category: "Bottoms", size: '31"' },
    { type: "Womenswear", category: "Bottoms", size: '32"' },
    { type: "Womenswear", category: "Bottoms", size: '33"' },
    { type: "Womenswear", category: "Bottoms", size: '34"' },

    { type: "Womenswear", category: "Dresses", size: "4" },
    { type: "Womenswear", category: "Dresses", size: "6" },
    { type: "Womenswear", category: "Dresses", size: "8" },
    { type: "Womenswear", category: "Dresses", size: "10" },
    { type: "Womenswear", category: "Dresses", size: "12" },
    { type: "Womenswear", category: "Dresses", size: "14" },
    { type: "Womenswear", category: "Dresses", size: "16" },
    { type: "Womenswear", category: "Dresses", size: "18" },
    { type: "Womenswear", category: "Dresses", size: "20" },
    { type: "Womenswear", category: "Dresses", size: "22" },
    { type: "Womenswear", category: "Dresses", size: "24" },
    { type: "Womenswear", category: "Dresses", size: "XXS" },
    { type: "Womenswear", category: "Dresses", size: "XS" },
    { type: "Womenswear", category: "Dresses", size: "S" },
    { type: "Womenswear", category: "Dresses", size: "M" },
    { type: "Womenswear", category: "Dresses", size: "L" },
    { type: "Womenswear", category: "Dresses", size: "XL" },
    { type: "Womenswear", category: "Dresses", size: "XXL" },

    { type: "Womenswear", category: "Swimwear", size: "4" },
    { type: "Womenswear", category: "Swimwear", size: "6" },
    { type: "Womenswear", category: "Swimwear", size: "8" },
    { type: "Womenswear", category: "Swimwear", size: "10" },
    { type: "Womenswear", category: "Swimwear", size: "12" },
    { type: "Womenswear", category: "Swimwear", size: "14" },
    { type: "Womenswear", category: "Swimwear", size: "16" },
    { type: "Womenswear", category: "Swimwear", size: "18" },
    { type: "Womenswear", category: "Swimwear", size: "20" },
    { type: "Womenswear", category: "Swimwear", size: "22" },
    { type: "Womenswear", category: "Swimwear", size: "24" },
    { type: "Womenswear", category: "Swimwear", size: "XXS" },
    { type: "Womenswear", category: "Swimwear", size: "XS" },
    { type: "Womenswear", category: "Swimwear", size: "S" },
    { type: "Womenswear", category: "Swimwear", size: "M" },
    { type: "Womenswear", category: "Swimwear", size: "L" },
    { type: "Womenswear", category: "Swimwear", size: "XL" },
    { type: "Womenswear", category: "Swimwear", size: "XXL" },
    { type: "Womenswear", category: "Swimwear", size: "28 D" },
    { type: "Womenswear", category: "Swimwear", size: "30 A " },
    { type: "Womenswear", category: "Swimwear", size: "30 B" },
    { type: "Womenswear", category: "Swimwear", size: "30 C " },
    { type: "Womenswear", category: "Swimwear", size: "30 D" },
    { type: "Womenswear", category: "Swimwear", size: "30 DD" },
    { type: "Womenswear", category: "Swimwear", size: "32 A" },
    { type: "Womenswear", category: "Swimwear", size: "32 B" },
    { type: "Womenswear", category: "Swimwear", size: "32 C" },
    { type: "Womenswear", category: "Swimwear", size: "32 D" },
    { type: "Womenswear", category: "Swimwear", size: "32 DD" },
    { type: "Womenswear", category: "Swimwear", size: "32 E" },
    { type: "Womenswear", category: "Swimwear", size: "34 A" },
    { type: "Womenswear", category: "Swimwear", size: "34 B" },
    { type: "Womenswear", category: "Swimwear", size: "34 C" },
    { type: "Womenswear", category: "Swimwear", size: "34 D" },
    { type: "Womenswear", category: "Swimwear", size: "34 DD" },
    { type: "Womenswear", category: "Swimwear", size: "34 E " },
    { type: "Womenswear", category: "Swimwear", size: "36 A" },
    { type: "Womenswear", category: "Swimwear", size: "36 B " },
    { type: "Womenswear", category: "Swimwear", size: "36 C" },
    { type: "Womenswear", category: "Swimwear", size: "36 D " },
    { type: "Womenswear", category: "Swimwear", size: "36 DD" },
    { type: "Womenswear", category: "Swimwear", size: "36 E" },
    { type: "Womenswear", category: "Swimwear", size: "38A " },
    { type: "Womenswear", category: "Swimwear", size: "38 B" },
    { type: "Womenswear", category: "Swimwear", size: "38 C" },
    { type: "Womenswear", category: "Swimwear", size: "38 D" },
    { type: "Womenswear", category: "Swimwear", size: "38 DD" },
    { type: "Womenswear", category: "Swimwear", size: "38 E" },
    { type: "Womenswear", category: "Swimwear", size: "38 F" },
    { type: "Womenswear", category: "Swimwear", size: "38 G" },
    { type: "Womenswear", category: "Swimwear", size: "A Cup" },
    { type: "Womenswear", category: "Swimwear", size: "B Cup " },
    { type: "Womenswear", category: "Swimwear", size: "C Cup " },
    { type: "Womenswear", category: "Swimwear", size: "D Cup " },
    { type: "Womenswear", category: "Swimwear", size: "DD Cup " },
    { type: "Womenswear", category: "Swimwear", size: "E Cup " },
    { type: "Womenswear", category: "Swimwear", size: "F Cup " },

    { type: "Womenswear", category: "Outerwear", size: "4" },
    { type: "Womenswear", category: "Outerwear", size: "6" },
    { type: "Womenswear", category: "Outerwear", size: "8" },
    { type: "Womenswear", category: "Outerwear", size: "10" },
    { type: "Womenswear", category: "Outerwear", size: "12" },
    { type: "Womenswear", category: "Outerwear", size: "14" },
    { type: "Womenswear", category: "Outerwear", size: "16" },
    { type: "Womenswear", category: "Outerwear", size: "18" },
    { type: "Womenswear", category: "Outerwear", size: "20" },
    { type: "Womenswear", category: "Outerwear", size: "22" },
    { type: "Womenswear", category: "Outerwear", size: "24" },
    { type: "Womenswear", category: "Outerwear", size: "XXS" },
    { type: "Womenswear", category: "Outerwear", size: "XS" },
    { type: "Womenswear", category: "Outerwear", size: "S" },
    { type: "Womenswear", category: "Outerwear", size: "M" },
    { type: "Womenswear", category: "Outerwear", size: "L" },
    { type: "Womenswear", category: "Outerwear", size: "XL" },
    { type: "Womenswear", category: "Outerwear", size: "XXL" },
    { type: "Womenswear", category: "Outerwear", size: "XXXL" },

    { type: "Womenswear", category: "Footwear", size: "UK 3" },
    { type: "Womenswear", category: "Footwear", size: "UK 3 1/2" },
    { type: "Womenswear", category: "Footwear", size: "UK 4" },
    { type: "Womenswear", category: "Footwear", size: "UK 4 1/2" },
    { type: "Womenswear", category: "Footwear", size: "UK 5 " },
    { type: "Womenswear", category: "Footwear", size: "UK 5 1/2" },
    { type: "Womenswear", category: "Footwear", size: "UK 6 " },
    { type: "Womenswear", category: "Footwear", size: "UK 6 1/2" },
    { type: "Womenswear", category: "Footwear", size: "UK 7 " },
    { type: "Womenswear", category: "Footwear", size: "UK 7 1/2" },
    { type: "Womenswear", category: "Footwear", size: "UK 8 " },
    { type: "Womenswear", category: "Footwear", size: "UK 8 1/2" },
    { type: "Womenswear", category: "Footwear", size: "UK 9 " },

    { type: "Womenswear", category: "Swimwear", size: "4" },
    { type: "Womenswear", category: "Swimwear", size: "6" },
    { type: "Womenswear", category: "Swimwear", size: "8" },
    { type: "Womenswear", category: "Swimwear", size: "10" },
    { type: "Womenswear", category: "Swimwear", size: "12" },
    { type: "Womenswear", category: "Swimwear", size: "14" },
    { type: "Womenswear", category: "Swimwear", size: "16" },
    { type: "Womenswear", category: "Swimwear", size: "18" },
    { type: "Womenswear", category: "Swimwear", size: "20" },
    { type: "Womenswear", category: "Swimwear", size: "22" },
    { type: "Womenswear", category: "Swimwear", size: "24" },
    { type: "Womenswear", category: "Swimwear", size: "XXS" },
    { type: "Womenswear", category: "Swimwear", size: "XS" },
    { type: "Womenswear", category: "Swimwear", size: "S" },
    { type: "Womenswear", category: "Swimwear", size: "M" },
    { type: "Womenswear", category: "Swimwear", size: "L" },
    { type: "Womenswear", category: "Swimwear", size: "XL" },
    { type: "Womenswear", category: "Swimwear", size: "XXL" },
    { type: "Womenswear", category: "Swimwear", size: "28 D" },
    { type: "Womenswear", category: "Swimwear", size: "30 A " },
    { type: "Womenswear", category: "Swimwear", size: "30 B" },
    { type: "Womenswear", category: "Swimwear", size: "30 C " },
    { type: "Womenswear", category: "Swimwear", size: "30 D" },
    { type: "Womenswear", category: "Swimwear", size: "30 DD" },
    { type: "Womenswear", category: "Swimwear", size: "32 A" },
    { type: "Womenswear", category: "Swimwear", size: "32 B" },
    { type: "Womenswear", category: "Swimwear", size: "32 C" },
    { type: "Womenswear", category: "Swimwear", size: "32 D" },
    { type: "Womenswear", category: "Swimwear", size: "32 DD" },
    { type: "Womenswear", category: "Swimwear", size: "32 E" },
    { type: "Womenswear", category: "Swimwear", size: "34 A" },
    { type: "Womenswear", category: "Swimwear", size: "34 B" },
    { type: "Womenswear", category: "Swimwear", size: "34 C" },
    { type: "Womenswear", category: "Swimwear", size: "34 D" },
    { type: "Womenswear", category: "Swimwear", size: "34 DD" },
    { type: "Womenswear", category: "Swimwear", size: "34 E " },
    { type: "Womenswear", category: "Swimwear", size: "36 A" },
    { type: "Womenswear", category: "Swimwear", size: "36 B " },
    { type: "Womenswear", category: "Swimwear", size: "36 C" },
    { type: "Womenswear", category: "Swimwear", size: "36 D " },
    { type: "Womenswear", category: "Swimwear", size: "36 DD" },
    { type: "Womenswear", category: "Swimwear", size: "36 E" },
    { type: "Womenswear", category: "Swimwear", size: "38A " },
    { type: "Womenswear", category: "Swimwear", size: "38 B" },
    { type: "Womenswear", category: "Swimwear", size: "38 C" },
    { type: "Womenswear", category: "Swimwear", size: "38 D" },
    { type: "Womenswear", category: "Swimwear", size: "38 DD" },
    { type: "Womenswear", category: "Swimwear", size: "38 E" },
    { type: "Womenswear", category: "Swimwear", size: "38 F" },
    { type: "Womenswear", category: "Swimwear", size: "38 G" },
    { type: "Womenswear", category: "Swimwear", size: "A Cup" },
    { type: "Womenswear", category: "Swimwear", size: "B Cup " },
    { type: "Womenswear", category: "Swimwear", size: "C Cup " },
    { type: "Womenswear", category: "Swimwear", size: "D Cup " },
    { type: "Womenswear", category: "Swimwear", size: "DD Cup " },
    { type: "Womenswear", category: "Swimwear", size: "E Cup " },
    { type: "Womenswear", category: "Swimwear", size: "F Cup " },

    { type: "Kids", category: "Boys", size: "1-5 YEARS" },
    { type: "Kids", category: "Boys", size: "5 1/2- 10 YEARS" },
    { type: "Kids", category: "Boys", size: "11 1/2 - 15 YEARS" },
    { type: "Kids", category: "Boys", size: "15 +" },

    { type: "Kids", category: "Girls", size: "1-5 YEARS" },
    { type: "Kids", category: "Girls", size: "5 1/2- 10 YEARS" },
    { type: "Kids", category: "Girls", size: "11 1/2 - 15 YEARS" },
    { type: "Kids", category: "Girls", size: "15 +" },

    { type: "Kids", category: "Baby", size: "0-2 YEARS" },
];

export const getSizesForProductType = (type, category) => {
    return [...new Set(productSizes
        .filter(item => item.type === type && item.category === category)
        .map(item => item.size)
        .filter(item => item !== undefined)
    )];
}

export const getSizesForProductTypeFilter = (type, category) => {
    return getSizesForProductType(type, category)
        .map(item => ({ option: item, label: item }));
}


